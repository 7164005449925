.NavBar {
  background-color: rgb(31, 38, 59);
  height: fit-content;
  width: 100%;
  position:fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-size: 1.25rem;
  z-index: 1;
}

.Link {
  text-decoration: none;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1.5rem;
  color: white;
  font-family: sans-serif;
}

.Link:hover {
  text-decoration: none;
  color: #a24e12;
  cursor: pointer;
  transition: ease 0.25s transform;
  transform: translateX(0.5rem);
}

.Link:active {
  text-decoration: none;
  transition: ease 0.1s transform;
  transform: translateY(0.125rem);
}

.searchBar {
  font-family: sans-serif;
  padding: 0.125rem;
  margin: 0.25rem;
  height: fit-content;
  width: 20rem;
  border-radius: 0.5rem;
}

.button-search {
  font-family: sans-serif;
  background-color: #a24e12;
  color: white;
  height: fit-content;
  width: fit-content;
  position: initial;
  font-size: 1rem;
  padding: 0.35rem;
  border-radius: 0.5rem;
}

.search {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
