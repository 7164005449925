.contact{
  display: grid;
  grid-template-columns: auto;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5%;
  width: fit-content;
  padding: 1.5rem;
  justify-content: center;
  margin-left: 27.5%;
  margin-bottom: 4rem;
  margin-top: 2rem;
  color: #a24e12;
}

.table-contact td, .table-contact th {
  padding: 1.5rem;
  vertical-align: middle;
  font-size: 1.25rem;
}

.table-contact {
  font-family: sans-serif;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 0.5rem;
  color: #a24e12;
  box-shadow: 4px 4px #a24e12;
  border: 2px solid black;
}

.button {
  font-family: sans-serif;
  background-color: #a24e12;
  color: white;
  height: fit-content;
  width: 6rem;
  position: initial;
  padding: 0.75rem;
  border-radius: 0.5rem;
  margin-left: 9rem;
}

input {
  font-family: sans-serif;
  display: flex;
  padding: 0.25rem;
  margin: 0.5rem;
  height: 2rem;
  width: 15rem;
  border-radius: 0.5rem;
}

textarea{
  font-family: sans-serif;
  display: flex;
  padding: 0.25rem;
  margin: 1rem;
  width: 15rem;
  border-radius: 0.5rem;
}