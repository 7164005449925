.register {
  display: grid;
  grid-template-columns: auto;
  gap: 0.25rem;
  width: fit-content;
  padding: 1.5rem;
  justify-content: center;
  margin-bottom: 4rem;
  margin-top: 2rem;
  border-style: solid;
  border-color: black;
  border-radius: 0.5rem;
  color: #a24e12;
  margin-left: 34%;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
  font-size: 1.25rem;
}

.table {
  font-family: sans-serif;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #a24e12;
}

.register-button {
  font-family: sans-serif;
  background-color: #a24e12;
  color: white;
  height: 3rem;
  width: 10rem;
  margin-left: 5rem;
  position: relative;
  border-radius: 0.5rem;
}

input {
  font-family: sans-serif;
  display: flex;
  padding: 0.25rem;
  margin: 0.5rem;
  height: 2rem;
  width: 15rem;
  border-radius: 0.5rem;
}
