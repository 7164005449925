.admin-button {
  display: grid;
  padding: 0.7em 6.7em;
  margin: 0.8rem 22rem 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 1000;
  font-size: x-large;
  box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
    inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
    inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
  text-align: center;
  position: relative;
  color: black;
  width: 35rem;
}

.tableAdd {
  font-family: sans-serif;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #a24e12;
  margin-left: 28rem;
  margin-top: 3rem;
}

.tableEdit {
  font-family: sans-serif;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #a24e12;
  margin-left: 28rem;
  margin-top: 3rem;
}

.tableEdit td,
.tableEdit th {
  padding: 0.75rem;
  vertical-align: middle;
  font-size: 1.25rem;
}

.tableAdd td,
.tableAdd th {
  padding: 0.75rem;
  vertical-align: middle;
  font-size: 1.25rem;
}

.bookPage:active {
  box-shadow: inset 0 0.6em 2em -0.3em rgba(0, 0, 0, 0.15),
    inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
}

.button-Add {
  font-family: sans-serif;
  background-color: #a24e12;
  color: white;
  height: 3rem;
  width: 10rem;
  position: relative;
  border-radius: 0.5rem;
}

.delete-button {
  font-family: sans-serif;
  background-color: #a24e12;
  color: white;
  height: 3rem;
  width: 10rem;
  margin-left: 5rem;
  position: relative;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.tableDelete {
  font-family: sans-serif;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #a24e12;
  margin-left: 28rem;
  margin-top: 3rem;
}

.categories {
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 2rem;
  justify-items: center;
}

.h1 {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 5rem;
  font-weight: 400;
  text-align: center;
  color: rgb(162, 78, 18);
  margin-top: 2rem;
}

.author {
  border-radius: 5px;
  box-shadow: 4px 4px #a24e12;
  border: 1px black solid;
}

.categoryDetails {
  display: grid;
  grid-template-columns: auto auto auto;
  box-shadow: 4px 4px rgb(31, 38, 59);
  cursor: pointer;
  border-radius: 5px;
}

.categoryDetails:hover {
  transform: scale(1.05);
}

.imgCategoryDetails {
  border-right: solid 2px #a24e12;
  border-bottom: solid 2px #a24e12;
}

.AllCategory {
  display: grid;
  margin: 2rem;
  justify-content: center;
  grid-template-columns: 22.5% 22.5% 22.5% 22.5%;
}

.returnButton {
  font-family: sans-serif;
  background-color: #a24e12;
  color: white;
  height: 3rem;
  width: 10rem;
  margin-left: 59rem;
  position: relative;
  border-radius: 0.5rem;
  margin-top: 2rem;
}
