.footer {
  background-color: rgb(31, 38, 59);
  color: #d6d3c7;
  height: 9rem;
  text-align: center;
  justify-content: center;
  padding-top: 0.6rem;
  font-size: 1.25rem;
  font-family: sans-serif;
  margin-top: 190px;
}
