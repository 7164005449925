.vl {
  border-left: 6px solid green;
  height: 500px;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  margin-top: 100px;
}
.detailsWithoutImg {
  height: 50%;
  width: 60%;
  font-size: 1.5rem;
  /* border: 2px black solid;
        box-shadow: 4px 4px #A24E12; */
  text-align: right;
  margin-top: 5rem;
  /* border-radius:5px; */
  padding: 1.5rem;
  justify-content: right;
  margin-bottom: 30rem;
  margin-left: 20rem;
}
table td,
table th {
  padding: 0.75rem;
  vertical-align: middle;
  height: 20%;
}
table {
  font-family: sans-serif;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #a24e12;
}
.img {
  height: 36rem;
}
.addButton {
  font-family: sans-serif;
  background-color: #a24e12;
  color: white;
  height: 3rem;
  width: 10rem;
  /* margin-left: 59rem; */
  position: relative;
  border-radius: 0.5rem;
  margin-top: 2rem;
}
.buyButton {
  font-family: sans-serif;
  background-color: #a24e12;
  color: white;
  height: 3rem;
  width: 10rem;
  /* margin-left: 59rem; */
  position: relative;
  border-radius: 0.5rem;
  margin-top: 2rem;
}
.buttonBuyAdd {
  display: flex;
  justify-content: space-between;
}
