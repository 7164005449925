.Main {
  width: 100%;
  min-height: 30rem;
  padding-top: 4.5rem;
  z-index: 5;
}

html {
  --scrollbarBG: rgba(255, 255, 255, 0);
  --thumbBG: #a24e12;
}

body::-webkit-scrollbar {
  width: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}